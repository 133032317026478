@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #1d2229 #fff;
}

:root {
  --one: #a1f65e;
  --two: #524ff5;
  --three: #1d2229;
  --four: #6a6a6a;
  --five: #2c323a;
  --six: #f0f0f0;
  --seven: #d2d2d2;
  --white: #fff;
  --yellow: #ffd058;
  --main-transition: 0.3s;
}

body {
  font-family: "Roboto", sans-serif;
}

.container {
  width: 90%;
  margin: 0 auto;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  line-height: 1.5;
}

.margin-sections {
  margin-top: 90px;
}

@media (max-width: 900px) {
  .margin-sections {
    margin-top: 70px;
  }
}

.sections-padding {
  padding: 8rem 0;
}

.main-heading {
  color: var(--white);
  font-size: 50px;
  --x-offset: -0.0625em;
  --y-offset: 0.0625em;
  --stroke: 0.025em;
  --background-color: black;
  --stroke-color: lightblue;
  text-shadow: var(--x-offset) var(--y-offset) 0px var(--background-color),
    calc(var(--x-offset) - var(--stroke)) calc(var(--y-offset) + var(--stroke))
      0px var(--stroke-color);
}

.main-background {
  text-align: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Global Styles For All  Button*/
button {
  position: relative;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  z-index: 1;
}

button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  transition: var(--main-transition);
  -webkit-transition: var(--main-transition);
  -moz-transition: var(--main-transition);
  -ms-transition: var(--main-transition);
  -o-transition: var(--main-transition);
  z-index: -1;
}

button:hover::before {
  width: 100%;
}

/* Global P  */
.paragraph {
  margin-bottom: 5px;
  letter-spacing: 2px;
  color: var(--two);
  text-transform: uppercase;
  font-size: 14px;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: var(--white);
}

*::-webkit-scrollbar-track:hover {
  background-color: var(--white);
}

*::-webkit-scrollbar-track:active {
  background-color: var(--white);
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--three);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #1d2229;
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--three);
}
